.prefrenceButton{
  background: white;
  width: 165px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #5f7d9c;
  font-family: Roboto, Arial;
  font-size: 10px; color: #5f7d9c;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.header {
  width: 100%;
  height: 40px;
  background: #343A40;
}

.homeButton{
  image-rendering: pixelated;
  object-fit: scale-down;
  height: 38px;
  margin-left: 5px;
}

.siteTitle {
  position: absolute;
  color: white;
  font-size: 15px;
  right: 0;
  margin-right: 4px; 
  top:0;
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #343A40;
}

.legal {
  color: white;
  font-size: 8px;
  margin-left: 20px;
  margin-right: 20px;
}

.patreonLink {
  width: 35px;
  cursor: pointer;
  margin-top: 2px;
  margin-right: 5px;
}