// ---------------------------BOOTSTRAP OVERRIDES-------------------------------
// New colours added to bootstrap CSS.  Can be used as variants of bootstrap
// components, eg 'btn-red'.

// ---------------------APP WIDE CSS-----------------------

html, body {
  overflow-x: hidden;
}

body {
    position: relative;
    font-family: Content-font, Roboto, sans-serif;
    max-width: 100%;
    max-height: 100vh;
    background-color: #F5F5F5;
}

p {
  margin: auto;
}

h1 {
  font-size: 20px;
  font-weight: bolder;
}

.pageContainer {
  position: relative;
  display: flex;
  flex-flow: column;
  min-height: 100vh;


  ::-webkit-scrollbar {
    height: 0px;
    background: #343A40;
  }

  ::-webkit-scrollbar-thumb {
    background: White;
    border-radius: 10px;
  }
}

.contentContainer {
  flex: 1;
  height: 100vh;
  padding-bottom: 40px;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: White;
}

// HOME PAGE -------------------------------------------------------------------

.welcomePage {
  margin-left: 10px;
  margin-top: 50px;
}

.welcomeHome {
  width: 100%;
  align-items: center;
  display: flex;
}

.welcomeHomeButton {
  text-align: center;
  font-weight: bolder;
  height: 60px;
  margin: auto;
  cursor: pointer;
}
.welcomeHomeButton > img{
height: 80px;
margin-top: 10px;
margin-bottom: 10px;
}

.homeTitle {
font-size: 20px;
font-weight: bolder;
margin-left: 1%;
}

.menuSection {
  margin-top: 15px;
}

.popupBox {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform:  translate(-50%, -50%);
  border-radius: 2px;
  color: white;
  background-color: #1D1F20;
  width: 20%;
  z-index: 100;
}

.popupBox > h {
  position: relative;
  margin-left: 3px;
  text-align: center;
  font-weight: bolder;
}

.popupBox > p {
  position: relative;
  margin-left: 3px;
}

.gameSelectorMobile{
  display: flex;
  width: 100%;
  overflow: scroll;
}

.gameSelectorBrowser{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.teamSelector{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gen_box{
  display: flex;
  background-color: #343A40;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  padding: 5px;
}

.team_box{
  display: flex;
  background-color: #343A40;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 2.5px;
  margin-right: 4px;
  margin-bottom: 2.5px;
  padding: 5px;

  cursor: pointer;
}

.team_box:hover {
  transform: translateY(-1px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --borderWidth: 3px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth);
}

.team_box:hover:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.deleteTeam {
  color: #F5F5F5;
  font-size: 30px;
  font-weight: bolder;
  margin-left: 10px;
  margin-right: 10px;
}

.deleteTeam:hover {
  color: red;
  font-size: 40px;
}

.teamMemberImage{
  image-rendering: pixelated;
}
.emptyTeam{
  //position: absolute;
  bottom: 0;
  width: 100%;
  align-self: center;
  text-align: center;
  margin-top: 15px;
  font-weight: bolder;
}


.generationText{
  font-size: 25px;
  font-weight: bolder;
  text-align: center;
  color: white;
  margin-left: 3px;
}

.generationGames {
  display: flex;
  margin-left: 10px;
}

.gameCoverImageContainer{
  margin-right: 10px;
  height: 74px;
  cursor: pointer;
}


.gameCoverImage{
  max-height: 72px;
  height: auto;
  width: auto;
  filter: grayscale(20%);
}

.gameCoverImage:hover{
  filter: grayscale(0%);
}

.gameCoverImageContainer:hover {
  transform: translateY(-1px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --borderWidth: 3px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth);
}

.gameCoverImageContainer:hover:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}



// ERROR PAGE ------------------------------------------------------------------
.Errorbox {
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

.errorImage {
  position: absolute;
  left: 30%;
  top: 10%;
}

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 50%;
    width: 75%;
  }

.errorMessageTitle {
    margin-top: 50%;
    font-size: 17px;
  }

.errorMessage {
    color: grey;
    font-size: 17px;
  }

a {
  text-decoration: none;
  color: #68A090;
}

a:hover {
  text-decoration: underline;
}
